import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Logo from './logo.svg'
import AvatarButton from './AvatarButton';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useMediaQuery, useTheme } from '@mui/material';

export default function ButtonAppBar() {

  const theme = useTheme();
    const { user, signOut } = useAuthenticator((context) => [context.user]);


  return (
    <Box sx={{ flexGrow: 1 }} mb={2}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <img src={Logo} alt="SPORTPXL" />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Partners - Partenaires
          </Typography>
          <AvatarButton user={user} signOut={signOut}/>
        </Toolbar>
      </AppBar>
    </Box>
  );
}