
import { withAuthenticator, Authenticator, View, Heading, Button, useAuthenticator, Image, Text } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { Auth, I18n } from 'aws-amplify';
import awsconfig from './aws-exports';
import { Amplify } from 'aws-amplify';
import AppBar from './Appbar';
import { translations } from '@aws-amplify/ui-react';
import axios from 'axios';
// import {Typography, Link, Box, Container, Toolbar, Grid} from '@mui/material';
import { useTheme } from '@aws-amplify/ui-react';
import { Dashboard } from './Dashboard';
import Logo from './logo-white.svg'
import React from 'react';

Amplify.configure(awsconfig);
I18n.setLanguage('fr');
I18n.putVocabularies(translations);

const components = {
  Header() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Image
          alt="SPORTPXL PARTNERS"
          src={Logo}
        />
        &nbsp; &nbsp; ( Site Partenaires - Partners )
      </View>
    );
  },

  Footer() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Text color={tokens.colors.neutral[80]}>
          &copy; SportPXL
        </Text>
      </View>
    );
  },
};

axios.interceptors.request.use(
  (config) => {
    return Auth.currentSession().then(session => {
      const token = session.getIdToken().getJwtToken();
      config.headers['Authorization'] = `Bearer ${token}`;
      return config;
    })
  },
  (error) => {
    Promise.reject(error);
  }
);

export function App() {
  return (
    <main>
      <Authenticator components={components} hideSignUp={true} >
        {({signOut, user}) => (
          <>
            <AppBar />
            <Dashboard user={user}/>
            </>
        )}
      </Authenticator>  
      </main>
  );
}

export default App;