import React, { useState } from "react"
import { Alert, AlertTitle, Collapse, IconButton } from "@mui/material"
import { Close as CloseIcon } from "@mui/icons-material";

export const AlertClosable = ({title, message}) => {
    const [open, setOpen] = useState(true);
    return (
        <Collapse in={open}>
            <Alert severity='info' action={
                <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                    setOpen(false);
                }}
                >
                <CloseIcon fontSize="inherit" />
                </IconButton>
            }>
                <AlertTitle>{title}</AlertTitle>
                {message}
            </Alert>
        </Collapse>
    )
}

export default AlertClosable;