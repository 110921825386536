import React, { useEffect } from 'react';
import '@aws-amplify/ui-react/styles.css';
import { Alert, AlertTitle, Card, CardContent, ImageListItem, Container, Divider, Grid, Box, Typography, Button, useTheme, Accordion, AccordionSummary, AccordionDetails, IconButton, Dialog, DialogContent, useMediaQuery, DialogTitle } from '@mui/material';
import Invitations from 'Invitations';
import { NewInvitation } from 'NewInvitation';
import PricingCard from 'PricingCard';
import { Close, CreateRounded, ExpandMoreOutlined, HelpOutlineOutlined} from '@mui/icons-material';
import axios from 'axios';
export function Dashboard({ user }) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [openPreviewDiploma, setOpenPreviewDiploma] = React.useState(false);
  const isMobileResponsive = useMediaQuery(theme.breakpoints.down('md'));
  const [invitations, setInvitations] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(true)

  const getInvitations = () => {
      setIsLoading(true)
      return axios.get(`${process.env.REACT_APP_BASE_API_URL}/api/v1/partners/invitations`).then((response) => {
          setInvitations(response.data)
          setIsLoading(false)
      }).catch(err => {
          setIsLoading(false)
          console.log(err)
      })
  }

  useEffect(() => {
    getInvitations()
  }, [])


  const handleNewInvitationClick = () => {
    setOpen(true);
  }

  const handleNewInvitationClose = () => {
    setOpen(false);
  }

  const handleOpenPreviewDiploma = () => {
    setOpenPreviewDiploma(true);
  }

  const handleClosePreviewDiploma = () => {
    setOpenPreviewDiploma(false)
  }

  const handleNewInvitationSuccess = (newInvit) => {
    getInvitations();
    setOpen(false);
  }

  return (
    <Container maxWidth="xl" disableGutters>
      {open && <NewInvitation handleClose={handleNewInvitationClose} handleSuccess={handleNewInvitationSuccess}/>}
      {(!open && openPreviewDiploma) && (
        <Dialog open={true} onClose={handleClosePreviewDiploma} fullScreen={isMobileResponsive}>
          <DialogTitle>
            <Box display="flex" alignItems={"center"}>
              <Box flexGrow={1}>Aperçu des diplômes</Box>
              <Box>
                    <IconButton onClick={handleClosePreviewDiploma}>
                          <Close />
                    </IconButton>
                </Box>
            </Box>
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2" component="div" sx={{ flexGrow: 1 }}>
                  Aperçu du diplôme générique. <Typography variant="caption">(Photo de l'événement à 0.20 € / participant)</Typography>
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} >
                <ImageListItem>
                  <img
                    srcSet={`/generic_diploma_1.jpg`}
                    src={`/generic_diploma_1.jpg`}
                    alt={"item.title"}
                    loading="lazy"
                  />
                </ImageListItem>
              </Grid>
              <Grid item xs={12} md={6}>
                <ImageListItem>
                  <img
                    srcSet={`/generic_diploma_2.jpg`}
                    src={`/generic_diploma_2.jpg`}
                    alt={"item.title"}
                    loading="lazy"
                  />
                </ImageListItem>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2" component="div" sx={{ flexGrow: 1 }}>
                  Aperçu du diplôme personnalisé. <Typography variant="caption">(Photo de l'événement à 0.70 € / participant)</Typography>
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <ImageListItem key={"test"}>
                  <img
                    alt="Désolé indisponible"
                    srcSet={`/custom_diploma_2.jpg`}
                    src={`/custom_diploma_2.jpg`}
                    loading="lazy"
                  />
                </ImageListItem>
              </Grid>
              <Grid item xs={12} md={6}>
                <ImageListItem key={"test"}>
                  <img
                    alt="Désolé indisponible"
                    srcSet={`/custom_diploma_1.png`}
                    src={`/custom_diploma_1.png`}
                    loading="lazy"
                  />
                </ImageListItem>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      )}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Bienvenue {user.attributes.name} sur le site SportPXL Partners - Partenaires.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Alert severity='info'>
            <AlertTitle>Comment ça marche ?</AlertTitle>
            Etant partenaire de SportPXL vous disposez du droit d'inviter des événements à rejoindre SportPXL sans passer par nous. Vous trouverez ci-dessous les offres et les options que nous offrons aux événements.<br />
          </Alert>
        </Grid>
        <Grid item xs={12}>
          <Accordion elevation={0} defaultExpanded={isMobileResponsive ? false : true}>
            <AccordionSummary
              expandIcon={<ExpandMoreOutlined />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Typography variant="h6" >
                Offres et Options
              </Typography>
              <Typography variant="caption" ml={2} color="text.secondary">
                (Cliquez pour les ouvrir/cacher)
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={6} md={3} lg={2.4}>
                  <PricingCard name="Offre gratuite" credits={100} price={"Gratuit"} />
                </Grid>
                <Grid item xs={6} md={3} lg={2.4}>
                  <PricingCard name="Offre 1k" credits={1000} price={"24.90 €"} />
                </Grid>
                <Grid item xs={6} md={3} lg={2.4}>
                  <PricingCard name="Offre 5k" credits={5000} price={"99 €"} />
                </Grid>
                <Grid item xs={6} md={3} lg={2.4}>
                  <PricingCard name="Offre 10k" credits={10000} price={"149 €"} />
                </Grid>
                <Grid item xs={6} md={3} lg={2.4} >
                  <PricingCard name="Offre 30k" credits={30000} price={"399 €"} />
                </Grid>
                <Grid item xs={6} md={4.5} lg={6} height={"100%"} >
                  <Card variant='outlined' sx={{ borderColor: "gray", borderStyle: 'dashed' }}>
                    <CardContent>
                      <Typography variant="subtitle1" textAlign={"center"}>
                        Option Diplôme générique
                        <IconButton onClick={handleOpenPreviewDiploma}><HelpOutlineOutlined /></IconButton>
                      </Typography>
                      <Typography variant="subtitle2" mt={3.5} textAlign={"center"}>
                        <b>0,20 € / participant</b>
                      </Typography>
                      <Typography variant="caption">
                        Diplôme non personnalisé (Photo de l'événement)
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={6} md={4.5} lg={6}>
                  <Card variant='outlined' sx={{ borderColor: "gray", borderStyle: 'dashed' }}>
                    <CardContent>
                      <Typography variant="subtitle1" textAlign={"center"}>
                        Option Diplôme personnalisé
                        <IconButton onClick={handleOpenPreviewDiploma}><HelpOutlineOutlined /></IconButton>
                      </Typography>
                      <Typography variant="subtitle2" mt={3.5} textAlign={"center"}>
                        <b>0,70 € / participant</b>
                      </Typography>
                      <Typography variant="caption">
                        Diplôme non personnalisé (Photo du participant)
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Button size="large" variant='contained' color={"secondary"} fullWidth onClick={handleNewInvitationClick} startIcon={<CreateRounded />}>
            INVITER UN NOUVEL EVENEMENT
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Invitations invitations={invitations} isLoading={isLoading}/>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Dashboard;