import React from "react"
import { Chip } from "@mui/material"
export const CREDITS = {

    100: {
        price: 0,
        name: "Offre Gratuite",
        fullText: "Offre Gratuite - 100 photos (0€)"
    },
    1000: {
        price: 24.90,
        name: "Offre 1k",
        fullText: "Offre 1k - 1000 photos (24.90 €)"
    },
    5000: {
        price: 99,
        name: "Offre 5k",
        fullText: "Offre 5k - 5000 photos (99 €)"
    },
    10000: {
        price: 149,
        name: "Offre 10k",
        fullText: "Offre 10k - 10000 photos (149 €)"
    },
    30000: {
        price: 399,
        name: "Offre 30k",
        fullText: "Offre 30k - 30000 photos (399 €)"
    },
}

export const OPTIONS = {
    'none': {
        name: 'Aucune option',
        pricePerParticipants: 0
    },
    'generic': {
        name: 'Diplômes génériques',
        pricePerParticipants: 0.2
    },
    'custom': {
        name: 'Diplômes personnalisés',
        pricePerParticipants: 0.7
    }
}
export const STATUS = {
    'INVITED': {
        name: 'INVITÉ',
        chip: <Chip label="INVITÉ" color="warning" size="small" />
    },
    'BILLED': {
        name: 'A PAYER',
        chip: <Chip label="ACCEPTÉ" color="error" size="small" />
    },
    'PAID': {
        name: 'TERMINÉE',
        chip: <Chip label="PAYÉ" color="success" size="small" />
    },
}


export const ERRORS = {
    'Partners::Errors::NameAlreadyExists': 'Le nom du partenaire existe déjà sur SportPXL, veuillez en choisir un autre.',
    'Partners::Errors::EmailAlreadyExists': 'Le partenaire a dejà un compte sur SportPXL avec le mail saisi, veuillez en choisir un autre.',
}