import React from "react"
import {Card, CardContent, Typography} from '@mui/material'

export const PricingCard = ({name, price, credits}) => {
    return (
        <Card variant="outlined" >
            <CardContent>
                <Typography variant="h6" textAlign={"center"}>
                   {name}
                </Typography>
                <Typography variant="h5" mt={2}  textAlign={"center"}>
                    <b>{price}</b>
                </Typography>
                <Typography variant="caption" sx={{mt: 5}}>
                    {credits} crédits à utiliser sur 1 an.
                </Typography>
            </CardContent>
        </Card>
    )
}

export default PricingCard